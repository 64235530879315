import {
  Flex,
  Grid,
  GridItem,
  Button,
  Text,
  Image,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import { AppColors } from '../../theme';
import { dummyData } from '../external-reports/dummy-data';
import image from '../../assets/backlinkit_google_checker.png';

const LinkCheckerShowcase: React.FC = () => {
  const gridTemplate = useBreakpointValue({
    base: 'repeat(2, 1fr)',
    lg: '1fr',
  });
  const gridColumns = useBreakpointValue({
    base: '1fr',
    lg: 'repeat(2, 1fr)',
  });
  const titleSize = useBreakpointValue({
    base: '2xl',
    md: '4xl',
    lg: '5xl',
    xl: '5xl',
  });
  const textSize = useBreakpointValue({
    base: 'xs',
    sm: 'sm',
    md: 'sm',
  });
  return (
    <Grid
      gridTemplateColumns={gridColumns}
      gridTemplateRows={gridTemplate}
      gridRowGap={12}
      p={10}
      gridColumnGap={4}
    >
      <GridItem
        colSpan={1}
        rowStart={gridTemplate === 'repeat(2, 1fr)' ? 1 : 1}
        rowSpan={gridTemplate === 'repeat(2, 1fr)' ? 2 : 1}
        justifyContent={gridTemplate === '1fr' ? 'right' : 'left'}
      >
        <Flex
          w={'inherit'}
          justify={gridColumns === 'repeat(2, 1fr)' ? 'end' : 'center'}
          direction={'column'}
          align={{ base: 'start', lg: 'end' }}
          gap={6}
        >
          <Box maxW={{ base: '2xl', md: '3xl' }}>
            <Text
              textAlign={{ base: 'left' }}
              fontSize={titleSize}
              fontWeight={'800'}
              bgGradient={`linear(to-r, ${AppColors.secondary}, ${AppColors.primary})`}
              bgClip={'text'}
            >
              Google Index & Cache Checker!
            </Text>
            <Text textAlign={{ base: 'left' }} fontSize={textSize}>
              {dummyData.description}
            </Text>
          </Box>
          <Flex justify={'end'}>
            <Button
              border={`1px solid ${AppColors.appBorder}`}
              borderRadius={'full'}
              bgColor={'white'}
              maxW={'150px'}
              boxShadow={`-2px 2px 0px 2px ${AppColors.appBackground}`}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
            >
              Find a plan!
            </Button>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem
        colSpan={gridColumns === 'repeat(2, 1fr)' ? 1 : 2}
        gridColumnStart={gridColumns === 'repeat(2, 1fr)' ? 2 : 1}
      >
        <Flex align={'center'}>
          <Image src={image} w={{ base: '400px', md: '800px' }} />
        </Flex>
      </GridItem>
    </Grid>
  );
};

export default LinkCheckerShowcase;
