import { Box, Text, Image, Button, Flex, Icon, Link } from '@chakra-ui/react';
import { SearchBar } from '../searchBar/searchBar';
import rightPipe from '../../assets/bannerGraphics 2.png';
import leftPipe from '../../assets/bannerGraphics.png';
import rightArrow from '../../assets/right-arrow.svg';
import chainIcon from '../../assets/Vector Smart Object.png';
import { useBreakpointValue } from '@chakra-ui/react';
import { AppColors } from '../../theme';
import { FaArrowRight } from 'react-icons/fa6';
import { signupBetaUrl } from '@backlinkit/shared';

export const V2Hero: React.FC = () => {
  const variant = useBreakpointValue({
    base: 'mobile',
    sm: 'smallMobile',
    xl: 'desktop',
  });
  const topBox = useBreakpointValue({
    base: '50px',
    lg: '0',
  });
  const rightPipeTopMargin = useBreakpointValue({
    base: '200px',
    xs: '100px',
    sm: '150px',
    md: '25px',
    lg: '32px',
    xl: '115px',
  });
  const rightPipeSize = useBreakpointValue({
    base: '220px',
    md: '50vw',
    lg: '33vw',
    xl: '500px',
  });
  const leftPipeMargin = useBreakpointValue({
    base: '10%',
    sm: '500px',
    md: '550px',
    lg: '50px',
    xl: '122px',
  });
  const leftPipeSize = useBreakpointValue({
    base: '30vw',
    md: '28vw',
    lg: '30vw',
    xl: '500px',
  });
  const gradientBoxHeight = useBreakpointValue({
    base: '500px',
    sm: '500px',
    md: '550px',
    lg: '480px',
    xxl: '600px',
  });
  const titleSize = useBreakpointValue({
    base: '3xl',
    lg: '4xl',
    xl: '6xl',
  });
  const subheadingSize = useBreakpointValue({
    base: 'xl',
    xl: '3xl',
  });
  return (
    <Box overflow={'hidden'} mt={variant === 'desktop' ? '-15px' : 0}>
      <Image
        src={rightPipe}
        pos={'absolute'}
        w={rightPipeSize}
        right={'0'}
        alignItems={'center'}
        mt={rightPipeTopMargin}
        zIndex={1}
      />
      {variant !== 'smallMobile' && (
        <Image
          src={leftPipe}
          pos={'absolute'}
          left={{ xs: '0', lg: '33px' }}
          display={{ md: 'none', lg: 'block' }}
          w={leftPipeSize}
          mt={leftPipeMargin}
          transform={{ xs: 'scaleY(-1)', lg: 'none' }}
          zIndex={1}
        />
      )}
      <Box w={'55vw'} h={topBox} />

      <Flex flexDir={'column'} alignItems={'center'} zIndex={5} height={'100%'}>
        <Flex
          alignItems={variant !== 'desktop' ? 'center' : 'end'}
          justifyContent={'start'}
          h={'auto'}
          pb={'40px'}
          width={'100%'}
          zIndex={5}
        >
          <Flex
            fontSize={titleSize}
            fontWeight={'semibold'}
            lineHeight={'shorter'}
            flexDir={'column'}
            height={'100%'}
            maxW={'910px'}
            justifyContent={'start'}
            mx={variant === 'desktop' ? '33px' : '20px'}
          >
            <Box display={'flex'} alignItems={'center'}>
              <Text color={AppColors.primary}>The smart approach</Text>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <Text>to tracking your off-page</Text>
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={'3'}>
              <Text>SEO campaigns</Text>
            </Box>

            <Box maxW={{ xs: '100%', lg: '50%' }} mt={variant === 'desktop' ? '60px' : '30px'}>
              <Text fontSize={subheadingSize} color={AppColors.tertiary}>
                The edge to unparalleled link building for you or your valued clients
              </Text>
            </Box>
          </Flex>
        </Flex>
        <Box pos={'unset'} w={'100%'} height={'75px'} padding={4} mt={'30px'}>
          <Box display={'flex'} pos={'relative'}>
            <SearchBar
              zIndex={5}
              boxShadow={'-5px 5px rgba(0, 0, 0, 0.08)'}
              pos={'absolute'}
              top={'-50px'}
              left={0}
              right={0}
              m={'auto'}
            />
          </Box>
        </Box>

        <Flex
          flexDir={variant === 'desktop' ? 'row' : 'column'}
          overflow={'hidden'}
          mt={variant === 'desktop' ? '-60px' : '-80px'}
          bgGradient={
            variant === 'desktop'
              ? 'linear(to-r, #C4DEF7, #EDFCFF)'
              : 'linear(to-t, #C4DEF7, #EDFCFF)'
          }
          w={'100%'}
          h={gradientBoxHeight}
          borderRadius={'15px'}
          justify={'center'}
          align={'end'}
          pos={'relative'}
        >
          <Flex
            flexDir={variant === 'desktop' ? 'row' : 'column'}
            height={'70%'}
            align={'center'}
            justify={'center'}
            width={'100%'}
          >
            <Flex
              align={'center'}
              justify={{ base: 'center', lg: 'start' }}
              gap={'20px'}
              overflow={'hidden'}
              zIndex={5}
              mt={{ base: '20px', lg: 0 }}
              height={'70%'}
              flexDir={{ base: 'column', lg: 'row' }}
            >
              <Link href={signupBetaUrl}>
                <Button
                  zIndex={5}
                  borderRadius={'12px'}
                  display={'flex'}
                  gap={'3'}
                  p={4}
                  height={'auto'}
                  w={'auto'}
                  bgGradient="linear(to-r, aqua 2%, blue)"
                  _hover={{
                    bgGradient: 'linear(to-r, aqua 2%, blue)',
                  }}
                >
                  <Box fontSize={'sm'} color={'white'}>
                    <Text>Start beta testing</Text>
                    <Text>Be one of the first</Text>
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    bgColor={'#2ED5FA'}
                    height={'22px'}
                    w={'22px'}
                    borderRadius={'100%'}
                  >
                    <Image w={'10px'} src={rightArrow} />
                  </Box>
                </Button>
              </Link>
              {variant === 'desktop' && <Icon as={FaArrowRight} color={AppColors.secondary} />}
              <Box
                fontSize={variant === 'desktop' ? 'auto' : '15px'}
                w={variant === 'desktop' ? '200px' : '120px'}
              >
                <Box display={'flex'} gap={'2'}>
                  <Text>100 Google checks</Text>
                </Box>
                <Text>Track 1000 links</Text>
              </Box>
            </Flex>

            <Flex
              overflow={'hidden'}
              gap={'3'}
              backgroundColor={'white'}
              align={'center'}
              p={4}
              borderRadius={'15px'}
              boxShadow={'-5px 5px rgba(0, 0, 0, 0.08)'}
              zIndex={2}
            >
              <Flex gap={'2'}>
                <Image src={chainIcon} alignSelf={'center'} w={'50px'} />
                <Box
                  display={'flex'}
                  flexDir={'column'}
                  w={variant === 'desktop' ? '190px' : 'auto'}
                >
                  <Text fontWeight={'semibold'}>Monitor unlimited</Text>
                  <Text>domain campaigns</Text>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
