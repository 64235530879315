import { Flex, Image, useBreakpointValue } from '@chakra-ui/react';
import AppText from '../app-text/app-text';
import sheets from '../../assets/Icons (2).png';
import board from '../../assets/Icons (5).png';
import megaphone from '../../assets/Icons (4).png';
import drawer from '../../assets/Icons (3).png';
import cogs from '../../assets/Icons (1).png';

export const FeatureList: React.FC = () => {
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  const list = [
    {
      icon: sheets,
      label: 'Whois checker',
    },
    {
      icon: board,
      label: 'Link recovery',
    },
    {
      icon: megaphone,
      label: 'Link tracker',
    },
    {
      icon: drawer,
      label: 'Link hub',
    },
    {
      icon: cogs,
      label: 'Google checker',
    },
  ];
  return (
    <Flex
      boxShadow={'-5px 5px rgba(0, 0, 0, 0.08)'}
      borderRadius={'15px'}
      overflowX={variant === 'desktop' ? 'hidden' : 'scroll'}
      overflowY={'hidden'}
      width={'auto'}
      height={'100px'}
      p={4}
      justify={'space-evenly'}
      gap={'10px'}
      align={'center'}
      mx={4}
      mt={variant === 'desktop' ? '40px' : 0}
    >
      {list.map((item, idx) => (
        <Flex key={`${item.label}-${idx}`} align={'center'} minW={'100px'} gap={'10px'} px={2}>
          <Image maxH={'30px'} width={'auto'} src={item.icon} />
          <AppText>{item.label}</AppText>
        </Flex>
      ))}
    </Flex>
  );
};
