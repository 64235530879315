import { Flex, Stack, Text, Button, Box, List, Icon, useBreakpointValue } from '@chakra-ui/react';
import { AppColors } from '../../theme';
import { ProductDto } from '../../models/products';
import { CheckCircleIcon } from '@chakra-ui/icons';

export interface CardProps {
  products: ProductDto[];
  onPlanSelect?: (data: string) => void;
}

const PriceCards: React.FC<CardProps> = ({ products, onPlanSelect }) => {
  const cardGap = useBreakpointValue({
    base: 8,
    lg: 2,
    xl: 8
  })
  return (
    <Flex
      w={'full'}
      direction={{ base: 'column', lg: 'row' }}
      gap={cardGap}
      justify={'center'}
      align={'center'}
      py={12}
    >
      {products.map((x) => {
        return (
          <Flex
            key={x.id}
            justify={'space-evenly'}
            pos={'relative'}
            boxShadow={'xl'}
            gap={8}
            w={'280px'}
            direction={'column'}
            borderRadius={'2xl'}
            border={
              x.name === 'Basic Plan'
                ? `1.5px solid ${AppColors.secondary}`
                : `1px solid ${AppColors.appBorder}`
            }
            bgColor={'white'}
          >
            <Flex
              visibility={x.name === 'Basic Plan' ? 'visible' : 'hidden'}
              borderRadius={'xl'}
              bgColor={AppColors.primary}
              pos={'absolute'}
              left={'50%'}
              top={'-16px'}
              style={{ transform: 'translate(-50%)' }}
            >
              <Text
                textTransform={'uppercase'}
                color={'white'}
                px={3}
                py={1}
                fontSize={'sm'}
                fontWeight={'600'}
                rounded={'xl'}
              >
                Most Popular
              </Text>
            </Flex>
            <Box
              w={'full'}
              p={4}
              borderTopRadius={'2xl'}
              bgColor={
                x.name === 'Basic Plan'
                  ? `${AppColors.secondary}`
                  : `${AppColors.appBackground}`
              }
            >
              <Text
                fontSize={'3xl'}
                fontWeight={'600'}
                textAlign={'center'}
                color={x.name === 'Basic Plan' ? 'white' : 'black'}
              >
                {x.name}
              </Text>
              <Flex align={'center'} justify={'center'}>
                <Text
                  textAlign={'center'}
                  fontSize={'2xl'}
                  fontWeight={'500'}
                  color={x.name === 'Basic Plan' ? 'white' : 'black'}
                >
                  {x.price === 0 ? `${x.price}` : `$${x.price}`}
                </Text>
                <Text
                  fontSize={'2xl'}
                  fontWeight={'500'}
                  color={x.name === 'Basic Plan' ? 'white' : 'black'}
                >
                  / month
                </Text>
              </Flex>
            </Box>
            <Stack spacing={3} p={{ base: 4, xl: 6 }}>
              <List spacing={3}>
                <Text>{x.description}</Text>
                <Flex align={'center'} gap={3}>
                  <Icon as={CheckCircleIcon} color={'green.500'} />
                  <Text>{`${x.amount} links`}</Text>
                </Flex>
              </List>
            </Stack>
            <Button
              m={6}
              bgColor={
                x.name === 'Basic Plan' ? `${AppColors.secondary}` : 'white'
              }
              borderRadius={'full'}
              border={
                x.name === 'Basic Plan'
                  ? `1px solid ${AppColors.secondary}`
                  : `1px solid ${AppColors.appBorder}`
              }
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
              color={x.name === 'Basic Plan' ? 'white' : 'black'}
              onClick={() => onPlanSelect}
            >
              Select Plan
            </Button>
          </Flex>
        );
      })}
    </Flex>
  );
};
export default PriceCards;
