import { Flex, Heading, useBreakpointValue, Text, Box } from "@chakra-ui/react";
import { AppColors } from "../../theme";

const PricingHero: React.FC = () => {
  const variant = useBreakpointValue({
    base: "mobile",
    lg: "desktop",
  });
  return (
    <Flex
      direction={"column"}
      gap={12}
      mt={{base: '-50px', lg: "-200px"}}
      bgColor={AppColors.appBorder}
      h={{ base: "5xl", md: "5xl", xl: "3xl" }}
      align={variant === "desktop" ? "center" : "left"}
      justify={"center"}
      px={variant === "desktop" ? 0 : 4}
    >
      <Heading
        as={"h1"}
        fontSize={{ base: "6xl", md: "7xl" }}
        fontWeight={"800"}
        lineHeight={variant === "desktop" ? "50%" : "100%"}
        pt={24}
      >
        <Text color={AppColors.secondary}>Make money</Text>
        <br hidden={variant === "desktop" ? false : true} />
        <Text color={AppColors.primary}>from your backlinks</Text>
      </Heading>
      <Box maxW={"600px"}>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit.
        </Text>
      </Box>
      <Heading
        as={"h1"}
        fontSize={{ base: "5xl", md: "6xl" }}
        fontWeight={"800"}
        textAlign={variant === "mobile" ? "center" : "left"}
      >
        Plans
      </Heading>
    </Flex>
  );
};

export default PricingHero;