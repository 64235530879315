import { BacklinkEFLLink } from './backlink';

export type IpInformation = {
  ip: string;
  country: string;
};

export type TLD = { tld: string; domain: string; sub: string };

export type TldInformation = {
  country: string;
  tld: TLD;
};

export enum RelStatus {
  follow = 'follow',
  noFollow = 'nofollow',
  error = 'error',
}

export enum RelValues {
  ugc = 'ugc',
  sponsored = 'sponsored',
  noFollow = 'nofollow',
}

export type CacheStatus = {
  cacheState: boolean;
  cacheCreatedDate: Date | undefined;
};

export type EflInformation = {
  externalNoFollowCount: number;
  externalFollowCount: number;
  internalCount: number;
  capturedEflLinks: BacklinkEFLLink[];
};

export type GoogleIndexStatusInformation = {
  domainIndex: boolean;
  pageIndex: boolean;
};

export type SslInformation = {
  valid: boolean;
  validFrom: string;
  validTo: string;
  daysRemaining: number;
  validFor: string[];
};

export type LinkStatusInformation = {
  relStatus: RelStatus;
  relValue: string;
  linkStatus: LinkStatus;
  lastLive: Date | undefined;
  landingPageHref: string;
  anchorText: string;
  anchorContextInfo: string;
  robotsIndexStatus: RobotsIndexStatus;
  linkType: LinkType;
  addtionalAnchorMeta: AnchorMetaData[];
};

export type AnchorMetaData = {
  anchorText: string;
  anchorContext: string;
  anchorRelValue: string;
  anchorRef: string;
  linkType: LinkType;
};

export enum LinkStatus {
  Ok = 'Ok',
  LinkNotFound = 'LinkNotFound',
  AnchorTextMismatch = 'AnchorTextMismatch',
  InternalError = 'InternalError',
  NotFound = 'NotFound',
  Unauthorized = 'Unauthorized',
  Gone = 'Gone',
}

export enum LinkType {
  Link = 'link',
  Image = 'image',
  Redirect = 'redirect',
}

export enum ScrapeErrorKeys {
  linkStatus,
  rel,
  relStatus,
  ip,
  ipCountry,
  efl,
  tld,
  tldDomain,
  cacheStatus,
  general,
  ssl,
}

export enum ScrapeErrorTypes {
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export enum RobotsIndexStatus {
  Unset = 'unset',
  Indexed = 'index',
  NoIndex = 'noindex',
}

export type ScrapeError = {
  key: ScrapeErrorKeys;
  errorMessage: string;
  errorType: ScrapeErrorTypes;
  dateCreated: Date;
};

export type LinkMeta = {
  tldCountry?: string;
  tld?: string;
  tldDomain?: string;
  tldSubDomain?: string;
  ip?: string;
  ipCountry?: string;
  externalNoFollowCount?: number;
  externalFollowCount?: number;
  internalFollowCount?: number;
  relStatus?: RelStatus;
  linkStatus?: LinkStatus;
  relValue?: string;
  lastLiveDate?: Date;
  anchorContextInfo?: string;
  robotsIndexStatus?: RobotsIndexStatus;
  linkType?: LinkType;
  additionalDomainAnchors?: AnchorMetaData[];
  originalDomainCreationDate?: string;
  pageTitle?: string;
  pageDescription?: string;
  backlinkResponse?: backlinkResponse;
};

export interface backlinkResponse {
  originalResponse: number;
  response: number;
  methodType: ResponseMethodTypes;
  createdDate: Date;
}

export enum ResponseMethodTypes {
  Standard,
  StandardSlow,
  ExternalApi,
  Manual,
  Proxy,
  Cache,
}

// export interface ScraperValueDifference {
//   key: string;
//   currentValue: string;
//   previousValue: string;
// }

export interface ScraperValueDifference {
  currentValue: string;
  previousValue: string;
  isEqual: boolean;
}

export interface BacklinkScraperValueDifference {
  relStatus?: ScraperValueDifference;
  relValue?: ScraperValueDifference;
  anchorContextInfo?: ScraperValueDifference;
  robotsIndexStatus?: ScraperValueDifference;
  landingPage?: ScraperValueDifference;
  anchor?: ScraperValueDifference;
  cacheCreatedDate?: ScraperValueDifference;
  hasDomainIndex?: ScraperValueDifference;
  hasPageIndex?: ScraperValueDifference;
  differenceCount?: number;
}
