export enum ColumnSpecialTypes {
  date = 'date',
}

type FlattenObjectKeys<
  T extends Record<string | number, unknown>,
  Key = keyof T,
> = Key extends string
  ? T[Key] extends Record<string | number, unknown>
    ? `${Key}.${FlattenObjectKeys<T[Key]>}`
    : `${Key}`
  : never;

export type ColumnDefinitionType<T extends {}> = {
  key: FlattenObjectKeys<T> | 'custom';
  header: string;
  headerMeta?: string;
  type?: ColumnSpecialTypes;
  width?: number;
  headerSortDir?: ColumnSortDir;
  headerSortable?: boolean;
  isVisible?: boolean;
  columnSearchable?: boolean;
  cellDataClickable?: boolean;
  render?: (item: T) => React.ReactNode;
  stickyColumn?: boolean;
  stickyColumnPosition?: number;
};

export type TableProps<T extends {}> = {
  data: Array<T>;
  columns: Array<ColumnDefinitionType<T>>;
  selectableRows?: boolean;
  searchBar?: boolean;
  sortableHeaders?: boolean;
  showColumnVisibility?: boolean;
  showExportButton?: boolean;
  showExpanding?: boolean;
  exportFileName?: string;
  noDataMessage?: string;
  onRowSelected?: (selection: T[]) => void;
  onTableCellClick?: (clickEvent: CellClickEvent<T>) => void;
  onSortByColumnClick?: (columns: ColumnDefinitionType<T>[]) => void;
  onSearchInputChange?: (searchEvent: TableSearchEventProps<T>) => void;
  isLoading?: boolean;
  customFilter?: React.ReactNode;
};

export type TableHeaderProps<T extends {}> = {
  columns: Array<ColumnDefinitionType<T>>;
  columnSortDir?: ColumnSortDir;
  sortingColumn?: ColumnDefinitionType<T>;
  onSortByColumn?: (column: ColumnDefinitionType<T>) => void;
  onSelectAll?: () => void;
  selectAll?: boolean;
  selectableRows: boolean;
};

export type TableSearchEventProps<T extends {}> = {
  search: string;
  selectedColumns: Array<ColumnDefinitionType<T>>;
};

export type TableRowsProps<T extends {}> = {
  data: Array<T>;
  columns: Array<ColumnDefinitionType<T>>;
  selectedRows: T[];
  selectableRows: boolean;
  showExpanding: boolean;
  onRowsSelectedChange?: (tableRowSelect: TableRowsSelect<T>) => void;
  onCellClick?: (clickEvent: CellClickEvent<T>) => void;
};

export type TableRowsSelect<T extends {}> = {
  index: number;
  checked: boolean;
  row: T;
};

export type CellClickEvent<T extends {}> = {
  column: ColumnDefinitionType<T>;
  row: T;
};

export enum ColumnSortDir {
  Asc = 'asc',
  Desc = 'desc',
}

export interface TableCsvExporterConfig {
  csvConfig?: CsvExporterConfig;
  exportFileName?: string;
  exportData?: any;
}

export interface CsvExporterConfig {
  headers: string[];
  actions: null[];
  keys: string[];
}

export interface TableRowExpandingProps {
  expandText: string;
  expandRender?: React.ReactNode;
}
