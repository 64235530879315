import { TabHeadings, Panel, FooterLinks } from '../models/types';
import iconOne from '../assets/Icons (1).png';
import iconTwo from '../assets/Icons (2).png';
import iconThree from '../assets/Icons (5).png';
import iconFour from '../assets/Icons (4).png';
import iconFive from '../assets/Icons (3).png';
import mainOne from '../assets/1.png';
import mainTwo from '../assets/2.png';
import mainThree from '../assets/3.png';
import mainFour from '../assets/4.png';
import mainFive from '../assets/5.png';

export const site = {
  header: {
    nav: {
      homeLink: 'Home',
      homeLinkUrl: '/',
      linkOne: 'Tools',
      linkOneUrl: '/tools',
      linkTwo: 'Resources',
      linkTwoUrl: '',
      linkThree: 'Pricing',
      linkThreeUrl: '/pricing',
      linkFour: 'Enterprise',
      linkFourUrl: '',
    },
    logins: {
      btnOne: 'Login',
      btnTwo: 'Sign Up',
    },
  },
  hero: {
    mainTextBlock: {
      lineOne: 'Unlock',
      lineTwo: 'Potential with',
      lineThree: 'our',
      lineFour: 'SEO toolkit',
    },
    subtext: {
      text: 'The tool that monitors your seo link building efforts',
    },
    searchBar: {
      placeHolder: 'Enter website URL// Google index, google Cache, Domain age',
      mobilePlaceholder: 'Enter website URL// ...',
      btnText: 'Check Now',
    },
    mainBtn: 'Sign Up for Backlinkit',
    mobileMainBtn: 'Sign Up',
    middleText: {
      lineOne: 'or get a free',
      lineTwo: '30 day account',
      lineThree: 'No credit card required',
    },
    rhsText: {
      lineOne: '100,000 link scrapped',
      lineTwo: 'by backlinkit in the last 7 days',
      lineThree: '7 days',
    },
  },
  featureSites: {
    topLine: 'As featured on these leading websites',
    images: [
      '../assets/adobe.5919f5a6180fd72d6db3.png',
      '../assets/ebay.48e7be84f243844d056b.png',
      '../assets/linkedIn.2076afaab2bee8c50262.png',
      '../assets/zoom.b2f7f89ff3b06590134c.png',
      '../assets/ibm.210a75adf8808da9c989.png',
      '../assets/johnsonJohnson.3bb6c05b6f6649403169.png',
    ],
  },
  footer: {
    title: {
      lineOne: 'Big ',
      colorWord: 'passion',
      lineTwo: 'for tech & SEO.',
    },
    rhsText: 'Big internet companies are making suboptimal to push market valuation.',
    btn: 'Sign Up for Backlinkit',
    bottomGrid: {
      leftItem: {
        copyright: '© 2023 Backlinkit. Ltd.',
      },
      rightItem: {
        columnOne: {
          title: 'Core tools',
        },
        columnTwo: {
          title: 'Extra Tools & features',
        },
        columnThree: {
          title: 'Resources',
        },
      },
    },
  },
};
export const tabs: TabHeadings[] = [
  { tabTitle: 'SEO', tabsImg: iconOne },
  { tabTitle: 'Content Marketing', tabsImg: iconTwo },
  { tabTitle: 'Market Researh', tabsImg: iconThree },
  { tabTitle: 'Social & Advertising', tabsImg: iconFour },
  { tabTitle: 'Agency Solution', tabsImg: iconFive },
];
export const servicePanels: Panel[] = [
  {
    lineOne: 'Be one of the first',
    title: 'Ready to beta test?',
    checklist: [
      "As a beta tester, you'll get exclusive early access to cutting-edge features designed to help you monitor backlink drop-offs and keep your SEO campaigns on track.s",
    ],
    checklistImg: 'assets/tick.svg',
    btn: 'Try our SEO Toolkit',
    mainImg: mainOne,
  },
  {
    lineOne: 'Content Marketing',
    title: 'Organic traffic our tool & workflow',
    checklist: [
      'Create & track PPC campaigns',
      'Shedule and post content on socials',
      'Run technical SEO audits',
    ],
    checklistImg: '../assets/tick.svg',
    btn: 'Try our SEO Toolkit',
    mainImg: mainTwo,
  },
  {
    lineOne: 'Market Research',
    title: 'Organic traffic our tool & workflow',
    checklist: [
      'Create & track PPC campaigns',
      'Shedule and post content on socials',
      'Run technical SEO audits',
    ],
    checklistImg: '../assets/tick.svg',
    btn: 'Try our SEO Toolkit',
    mainImg: mainThree,
  },
  {
    lineOne: 'Social & Advertising',
    title: 'Organic traffic our tool & workflow',
    checklist: [
      'Create & track PPC campaigns',
      'Shedule and post content on socials',
      'Run technical SEO audits',
    ],
    checklistImg: '../assets/tick.svg',
    btn: 'Try our SEO Toolkit',
    mainImg: mainFour,
  },
  {
    lineOne: 'Agency Solution',
    title: 'Organic traffic our tool & workflow',
    checklist: [
      'Create & track PPC campaigns',
      'Shedule and post content on socials',
      'Run technical SEO audits',
    ],
    checklistImg: '../assets/tick.svg',
    btn: 'Try our SEO Toolkit',
    mainImg: mainFive,
  },
];
export const footerLinks = {
  columnOne: [
    { url: '', label: 'DashBoard' },
    { url: '', label: 'Site Explorer' },
    { url: '', label: 'Keywords Explorer' },
    { url: '', label: 'Site Audit' },
  ],
  columnTwo: [
    { url: '', label: 'Domain Comparison' },
    { url: '', label: 'Batch Analysis' },
    { url: '', label: 'Link Intersect' },
    { url: '', label: 'Content Gap' },
  ],
  columnThree: [
    { url: '', label: 'Blog' },
    { url: '', label: 'Tech blog' },
    { url: '', label: 'SEO Guide' },
    { url: '', label: 'Academy' },
  ],
};
export const footerSocials: FooterLinks[] = [
  { url: '', label: 'Facebook' },
  { url: '', label: 'Youtube' },
  { url: '', label: 'LinkedIn' },
];
