import { createContext, useContext, useState } from 'react';
import { LoadingComponent } from './loading-component';

const LoadingContext = createContext({
  loading: false,
  setLoading: (show: boolean) => {},
});

export function LoadingProvider({ children }: any) {
  const [loading, handleLoadRessetting] = useState(false);

  const setLoading = (value: boolean) => {
    handleLoadRessetting(value);

    // RESET FOR INCASE
    setTimeout(() => {
      handleLoadRessetting(false);
    }, 5000);
  };

  const value = { loading, setLoading };
  return (
    <LoadingContext.Provider value={value}>
      {children}

      {loading && <LoadingComponent />}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
}
