import { Box, FormControl, Button, Image, Flex, FlexProps, Link } from '@chakra-ui/react';
import rightArrow from '../../assets/right-arrow.svg';
import { useBreakpointValue } from '@chakra-ui/react';
import { site } from '../../mock-data/siteData';
import { AppText, signupBetaUrl } from '@backlinkit/shared';

export interface SearchBarProps extends FlexProps {}

export const SearchBar: React.FC<SearchBarProps> = ({ ...props }: FlexProps) => {
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  const placeHolderText = useBreakpointValue({
    base: 'mobile',
    xl: 'desktop',
  });
  return (
    <Flex
      direction={{ base: 'column', lg: 'row' }}
      border={' solid #F4F5F7'}
      borderRadius={'2xl'}
      w={{ md: '100%', lg: '70%' }}
      p={{ base: 4, lg: 2 }}
      bgColor={'white'}
      gap={{ base: 3, lg: 0 }}
      justify={'center'}
      {...props}
    >
      <FormControl
        as={'input'}
        _focusVisible={{
          outline: 'none',
        }}
        fontFamily={'body'}
        fontSize={{ base: 'sm', lg: 'md' }}
        placeholder={
          placeHolderText === 'mobile'
            ? site.hero.searchBar.mobilePlaceholder
            : site.hero.searchBar.placeHolder
        }
        _placeholder={{
          fontFamily: 'poppins',
        }}
      />
      <Link href={signupBetaUrl}>
        <Button
          w={variant === 'desktop' ? '200px' : '100%'}
          h={'70px'}
          display={'flex'}
          fontSize={{ base: 'sm', lg: 'md' }}
          padding={0}
          gap={2}
          alignSelf={'center'}
          alignItems={'center'}
          bgColor={'#047DFC'}
          color={'white'}
          borderRadius={'12px'}
          _hover={{
            bgColor: '#047DFC',
          }}
        >
          <AppText>Check Now</AppText>
          <Box
            display={'flex'}
            borderRadius={'100%'}
            justifyContent={'center'}
            width={'25px'}
            h={'25px'}
            bgColor={'#2DD6FA'}
          >
            <Image w={'10px'} src={rightArrow} />
          </Box>
        </Button>
      </Link>
    </Flex>
  );
};
