import { Base } from './base';
import { Organization } from './organization';
import { ProductDto } from './products';

export interface OrganizationPaymentDto extends Base {
  paymentReference: string;
  organizationId?: string;
  organization?: Organization;
  productId?: string;
  product?: ProductDto;
  paymentAmount: number;
  paymentStatus: OrganizationPaymentStatusType;
}

export interface OrganizationPaymentForm {
  paymentReference: string;
  organizationId: string;
  productId?: string;
  paymentAmount: number;
  paymentStatus: OrganizationPaymentStatusType;
}

export enum OrganizationPaymentStatusType {
  INITIATED,
  CANCELLED,
  SUCCESS,
  FAILED,
}
