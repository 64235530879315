import React from "react";
import {
  Box,
  Heading,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useBreakpointValue,
} from "@chakra-ui/react";
import { AppColors } from "../../theme";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FAQ } from "../../models/faq";

interface FAQComponentProps {
  title: string;
  faqs: FAQ[];
}

const PopularFAQ = ({ title, faqs }: FAQComponentProps): JSX.Element => {
  const variant = useBreakpointValue({
    base: "300px",
    md: '450px',
    lg: '550px',
    xl: '800px'
  })
  
  return (
    <Flex align={"center"} py={12} pb={32} direction={"column"} gap={6}>
      <Heading as={"h1"} fontSize={"3xl"} fontWeight={"500"}>
        {title}
      </Heading>
      {faqs.map((x) => {
        return (
          <Flex
          key={x.question}
            bg={AppColors.appBackground}
            p={4}
            borderRadius={"2xl"}
            boxShadow={"lg"}
          >
            <Accordion allowToggle>
              <AccordionItem w={variant} border={"none"}>
                <AccordionButton
                  _hover={{
                    bgColor: "transparent",
                  }}
                >
                  <Box as={"span"} flex={1} textAlign={"left"}>
                    {x.question}
                  </Box>
                  <ChevronDownIcon w={5} h={5} />
                </AccordionButton>
                <AccordionPanel>{x.answer}</AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Flex>
        );
      })}
    </Flex>
  );
};
export default PopularFAQ;
