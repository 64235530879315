import { Box, Image, Button, useBreakpointValue, Link } from '@chakra-ui/react';
import logo from '../../assets/backlinkit_logo.svg';
import rightArrow from '../../assets/right-arrow.svg';
import { useState } from 'react';
import { AppColors } from '../../theme';
import AppText from '../../components/app-text/app-text';
import { loginUrl, signupBetaUrl } from '@backlinkit/shared';

export const Header = () => {
  const variant = useBreakpointValue(
    {
      base: '768px',
      lg: 'desktop',
    },
    { fallback: 'desktop' }
  );

  const [click, setClick] = useState('none');
  const handleClick = () => {
    click === 'none' ? setClick('flex') : setClick('none');
  };
  // const navWidth = useBreakpointValue({
  //   base: '600px',
  //   md: '450px',
  //   xl: '645px',
  // });
  const loginWidth = useBreakpointValue({
    base: '220px',
    md: '240px',
    xl: '260px',
  });

  return (
    <>
      {variant === 'desktop' && (
        <Box mt={'20px'} display={'grid'} gridTemplateColumns={'1fr 1fr'} pb={'100px'} mx={'33px'}>
          <Box
            pos={'relative'}
            bgColor={'white'}
            h={'105px'}
            w={{ md: '200px', xl: '320px' }}
            border={'2px solid #E8F2FD'}
            borderRadius={'15px'}
            display={'flex'}
            justifyContent={'center'}
            boxShadow={'-5px 5px rgba(0, 0, 0, 0.08)'}
          >
            <Image w={{ md: '180px', xl: '270px' }} src={logo} />
          </Box>

          {/* <Box
          display={'flex'}
          bgColor={'white'}
          mr={{md: '0', xl: '33px'}}
          flexDir={'row'}
          justifyContent={'space-evenly'}
          alignItems={'center'}
          h={'65px'}
          w={navWidth}
          borderStyle={'solid'}
          borderWidth={'2px'}
          borderRadius={'15px'}
          borderColor={'#E8F2FD'}
          boxShadow={'-5px 5px rgba(0, 0, 0, 0.08)'}
        >
          <Link fontWeight={'semibold'} href={site.header.nav.homeLinkUrl}>
            {site.header.nav.homeLink}
          </Link>
          <Link fontWeight={'semibold'} href={site.header.nav.linkOneUrl}>
            {site.header.nav.linkOne}
          </Link>
          <Link fontWeight={'semibold'} href={site.header.nav.linkThreeUrl}>
            {site.header.nav.linkThree}
          </Link>
        </Box> */}

          <Box
            display={'flex'}
            border={'2px solid #E8F2FD'}
            gridColumn={3}
            borderRadius={'15px'}
            h={'75px'}
            minW={loginWidth}
            px={'15px'}
            justifyContent={'space-evenly'}
            justifySelf={'end'}
            alignItems={'center'}
            boxShadow={'-5px 5px rgba(0, 0, 0, 0.08)'}
            gap={'15px'}
          >
            <Link href={loginUrl}>
              <Button
                py={4}
                p={0}
                height={'100%'}
                bgColor={'transparent'}
                _hover={{
                  bgColor: 'transparent',
                }}
              >
                <AppText fontSize={'sm'} fontWeight={'600'}>
                  Log in
                </AppText>
              </Button>
            </Link>
            <Link href={signupBetaUrl}>
              <Button
                height={'50px'}
                p={4}
                bgGradient={`linear(to-r, ${AppColors.secondary}, ${AppColors.primary})`}
                borderRadius={'12px'}
                display={'flex'}
                gap={'10px'}
              >
                <AppText fontSize={'sm'} fontWeight={'600'}>
                  Sign up
                </AppText>
                <Box
                  display={'flex'}
                  borderRadius={'100%'}
                  justifyContent={'center'}
                  boxSize={'25px'}
                  bgColor={AppColors.secondary}
                >
                  <Image w={'10px'} src={rightArrow} />
                </Box>
              </Button>
            </Link>
          </Box>
        </Box>
      )}

      {/* for mobile */}

      {variant !== 'desktop' && (
        <Box
          mt={'20px'}
          display={'flex'}
          flexDir={'column'}
          bgColor={'white'}
          zIndex={'7'}
          gap={8}
          minW={'100%'}
          h={click === 'flex' ? '100vh' : 'auto'}
          overflowY={click === 'flex' ? 'hidden' : 'visible'}
          gridTemplateColumns={'auto'}
          gridTemplateRows={'max-content'}
        >
          {/* logo  */}
          <Box
            pos={'relative'}
            bgColor={'white'}
            h={'100'}
            mx={'25px'}
            px={'10px'}
            border={'2px solid #E8F2FD'}
            borderRadius={'15px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            boxShadow={'-5px 5px rgba(0, 0, 0, 0.08)'}
          >
            <Image h={'65px'} w={'190px'} src={logo} />

            <Box
              pos={'absolute'}
              bgColor={'white'}
              right={'10px'}
              display={'flex'}
              flexDir={'column'}
              justifyContent={'space-evenly'}
              alignItems={'center'}
              w={'100px'}
              onClick={handleClick}
            >
              <span
                style={{
                  width: '50%',
                  height: '7px',
                  borderRadius: '3px',
                  backgroundColor: '#047DFC',
                }}
              />
              <span
                style={{
                  marginTop: '10%',
                  width: '30%',
                  height: '7px',
                  borderRadius: '3px',
                  backgroundColor: '#2BBCFA',
                }}
              />
            </Box>
          </Box>

          {/* nav  */}

          {/* <Box
            display={click}
            bgColor={'white'}
            mx={'25px'}
            flexDir={'column'}
            justifyContent={'space-evenly'}
            alignItems={'center'}
            h={'50vh'}
            gridRowStart={'2'}
            border={'2px solid #E8F2FD'}
            borderRadius={'15px'}
            boxShadow={'-5px 5px rgba(0, 0, 0, 0.08)'}
          >
            <Link fontWeight={'semibold'} href={site.header.nav.homeLinkUrl}>
              {site.header.nav.homeLink}
            </Link>
            <Link fontWeight={'semibold'} href={site.header.nav.linkOneUrl}>
              {site.header.nav.linkOne}
            </Link>
            <Link fontWeight={'semibold'} href={site.header.nav.linkThreeUrl}>
              {site.header.nav.linkThree}
            </Link>
          </Box> */}

          <Box
            display={click}
            bgColor={'white'}
            mx={'33px'}
            border={'2px solid #E8F2FD'}
            borderRadius={'15px'}
            h={'65px'}
            justifyContent={'space-evenly'}
            justifySelf={'end'}
            alignItems={'center'}
            boxShadow={'-5px 5px rgba(0, 0, 0, 0.08)'}
          >
            <Link href="https://portal.backlinkit.com/">
              <Button
                border={'none'}
                bgColor={'transparent'}
                _hover={{
                  bgColor: 'transparent',
                }}
                w={'35vw'}
              >
                <AppText fontSize={'sm'} fontWeight={'600'}>
                  Log in
                </AppText>
              </Button>
            </Link>
            <Link href={signupBetaUrl}>
              <Button
                bgColor={'transparent'}
                border={'2px solid #E8F2FD'}
                borderRadius={'12px'}
                display={'flex'}
                justifyContent={'space-around'}
                bgGradient={`linear(to-r, ${AppColors.secondary}, ${AppColors.primary})`}
                gap={'10px'}
                _hover={{ bgColor: 'transparent' }}
                padding={4}
              >
                <AppText fontSize={'sm'} fontWeight={'600'}>
                  Sign up
                </AppText>
                <Box
                  display={'flex'}
                  borderRadius={'100%'}
                  justifyContent={'center'}
                  boxSize={'20px'}
                  bgColor={'#2DD6FA'}
                >
                  <Image w={'10px'} src={rightArrow} />
                </Box>
              </Button>
            </Link>
          </Box>
        </Box>
      )}
    </>
  );
};
