import { Box, Text, Image, Button, Link } from "@chakra-ui/react";
import star from "../../assets/Vector Smart Object2.png";
import rightArrow from "../../assets/right-arrow.svg";
import { useState } from "react";
import expand from "../../assets/dropDown.svg";
import { site } from "../../mock-data/siteData";
import topRightPipe from "../../assets/CTA graphics.png";
import footerGraphic from "../../assets/Footer graphics.png";
import { FooterLinks } from "../../models/types";
import { footerLinks } from "../../mock-data/siteData";
import { footerSocials } from "../../mock-data/siteData";
import { useBreakpointValue } from "@chakra-ui/react";

export const Footer = () => {
  const variant = useBreakpointValue(
    {
      base: "mobile",
      lg: "desktop",
    },
    { fallback: "desktop" }
  );
  const titleSize = useBreakpointValue({
    base: "35px",
    sm: "4xl",
    md: "5xl",
    lg: "6xl",
  });
  const topBoxHeight = useBreakpointValue({
    base: "450px",
    sm: "450px",
    md: "500px",
    lg: "50%",
  });
  const pipeSize = useBreakpointValue({
    base: "150px",
    xs: "200px",
    md: "300px",
    xl: "400px",
    '2xl': '600px'
  });
  const pipeWidth = useBreakpointValue({
    base: "50%",
    md :"400px",
    lg: "250px",
    xl: "400px",
    '2xl': '600px'
  })
  const imgPos = useBreakpointValue({
    base: "375px",
    xs: "250px",
    sm: "300px",
    md: "350px",
    lg: "275px",
    xl: "175px"
  });
  const btnSize = useBreakpointValue({
    base: "260px",
    sm: "200px",
    md: "220px",
    lg: "250px",
    xl: "290px"
  });
  const [isOpen, setIsOpen] = useState("none");
  function handleDropOne() {
    isOpen === "none" ? setIsOpen("flex") : setIsOpen("none");
  }
  const [isOpenTwo, setIsOpenTwo] = useState("none");
  function handleDropTwo() {
    isOpenTwo === "none" ? setIsOpenTwo("flex") : setIsOpenTwo("none");
  }
  const [isOpenThree, setIsOpenThree] = useState("none");
  function handleDropThree() {
    isOpenThree === "none" ? setIsOpenThree("flex") : setIsOpenThree("none");
  }
  function linkGen(item: FooterLinks, i: number) {
    return (
      <Link
        key={i}
        href={item.url}
        fontSize={variant === "desktop" ? "auto" : "11px"}
      >
        {item.label}
      </Link>
    );
  }
  function genSocial(item: FooterLinks, x: number) {
    return (
      <Link
        key={x}
        href={item.url}
        display={"flex"}
        pos={"relative"}
        borderBottomWidth={variant === "desktop" ? "1px" : "0"}
        borderBottomColor={"rgb(255, 255, 255, 0.2)"}
        w={variant === "desktop" ? "55%" : "100%"}
        h={variant === "desktop" ? "80px" : "60px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        px={"10px"}
      >
        {item.label}
        <Image
          src={rightArrow}
          w={variant === "desktop" ? "auto" : "10px"}
          transform={"rotate(-45deg)"}
        />
      </Link>
    );
  }
  function MobileFooter() {
    return (
      <Box
        display={"flex"}
        flexDir={"column"}
        mx={"30px"}
        alignItems={"center"}
        bgColor={"black"}
        pos={"relative"}
        zIndex={"4"}
      >
        <Box
          borderBottomWidth={"1px"}
          borderBottomColor={"rgb(255, 255, 255, 0.2)"}
          w={"100%"}
          onClick={handleDropOne}
        >
          <Box
            display={"flex"}
            w={"100"}
            h={"70px"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              fontSize={variant === "desktop" ? "auto" : "14px"}
              fontWeight={"600"}
            >
              {site.footer.bottomGrid.rightItem.columnOne.title}
            </Text>
            <Image
              color={"white"}
              src={expand}
              transform={isOpen === "flex" ? "rotate(-90deg)" : "none"}
            />
          </Box>
          <Box
            display={isOpen}
            flexDir={"column"}
            gap={"20px"}
            mx={"10px"}
            pb={"20px"}
          >
            {footerLinks.columnOne.map(linkGen)}
          </Box>
        </Box>

        <Box
          borderBottomWidth={"1px"}
          borderBottomColor={"rgb(255, 255, 255, 0.2)"}
          w={"100%"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            w={"100"}
            height={"70px"}
            alignItems={"center"}
            onClick={handleDropTwo}
          >
            <Text
              fontSize={variant === "desktop" ? "auto" : "14px"}
              fontWeight={"600"}
            >
              {site.footer.bottomGrid.rightItem.columnTwo.title}
            </Text>
            <Image
              color={"white"}
              src={expand}
              transform={isOpenTwo === "flex" ? "rotate(-90deg)" : "rotate(0)"}
            />
          </Box>
          <Box display={isOpenTwo} flexDir={"column"} gap={"20px"} pb={"20px"}>
            {footerLinks.columnTwo.map(linkGen)}
          </Box>
        </Box>

        <Box
          borderBottomWidth={"1px"}
          borderBottomColor={"rgb(255, 255, 255, 0.2)"}
          w={"100%"}
        >
          <Box
            display={"flex"}
            height={"70px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            onClick={handleDropThree}
          >
            <Text
              fontSize={variant === "desktop" ? "auto" : "14px"}
              fontWeight={"600"}
            >
              {site.footer.bottomGrid.rightItem.columnThree.title}
            </Text>
            <Image
              color={"white"}
              src={expand}
              transform={
                isOpenThree === "flex" ? "rotate(-90deg)" : "rotate(0deg)"
              }
            />
          </Box>
          <Box
            display={isOpenThree}
            flexDir={"column"}
            gap={"20px"}
            pb={"20px"}
          >
            {footerLinks.columnThree.map(linkGen)}
          </Box>
        </Box>

        <Box
          display={variant === "desktop" ? "none" : "grid"}
          gridTemplateColumns={"1fr 1fr"}
          alignItems={"center"}
          pos={"relative"}
          h={"200px"}
          w={"100%"}
        >
          <Image
            src={footerGraphic}
            pos={"absolute"}
            bottom={"0"}
            h={"80%"}
            left={"0"}
          />
          {footerSocials.map(genSocial)}
          <Box
            gridColumnStart={"1"}
            color={"white"}
            gridColumnEnd={"3"}
            h={"80px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text>{site.footer.bottomGrid.leftItem.copyright}</Text>
          </Box>
          <span
            style={{
              backgroundColor: "rgb(255, 255, 255, 0.2)",
              width: "100%",
              height: "1px",
              position: "absolute",
              bottom: "80px",
            }}
          />
          <span
            style={{
              backgroundColor: "rgb(255, 255, 255, 0.2)",
              width: "100%",
              height: "1px",
              position: "absolute",
              bottom: "140px",
            }}
          />
          <span
            style={{
              backgroundColor: "rgb(255, 255, 255, 0.2)",
              width: "1px",
              height: "30px",
              position: "absolute",
              bottom: "95px",
              left: "50%",
            }}
          />
          <span
            style={{
              backgroundColor: "rgb(255, 255, 255, 0.2)",
              width: "1px",
              height: "30px",
              position: "absolute",
              bottom: "155px",
              left: "50%",
            }}
          />
        </Box>
      </Box>
    );
  }
  function DesktopFooter() {
    return (
      <Box
        display={"flex"}
        overflow={'hidden'}
        gridTemplateRows={"auto"}
        dir={"row"}
        w={"100%"}
        gridRow={"2"}
        h={"55%"}
        zIndex={5}
        pos={"relative"}
      >
        {/* social links */}
        <Box
          w={"33%"}
          display={"flex"}
          pos={"relative"}
          bgColor={"#141415"}
          borderRightStyle={"solid"}
          borderRightWidth={"2px"}
          borderRightColor={"rgb(255, 255, 255, 0.2)"}
        >
          <Image pos={"absolute"} bottom={"0"} h={"85%"} src={footerGraphic} />
          <Box
            w={"100%"}
            display={"flex"}
            alignItems={"end"}
            gap={0}
            gridTemplateColumns={"1fr 1fr"}
            flexDir={"column"}
            borderBottomWidth={"1px"}
            borderBottomColor={"rgb(255, 255, 255, 0.2)"}
          >
            {footerSocials.map(genSocial)}
            <Text
              display={"flex"}
              gridColumnStart={1}
              gridColumnEnd={3}
              w={"55%"}
              h={"80px"}
              alignItems={"center"}
              justifyContent={
                variant === "desktop" ? "space-between" : "center"
              }
            >
              {site.footer.bottomGrid.leftItem.copyright}
            </Text>
          </Box>
        </Box>
        <Box
          w={variant === "desktop" ? "67%" : "100%"}
          bgColor={"#141415"}
          display={"flex"}
          flexDir={variant === "desktop" ? "row" : "column"}
          pt={"60px"}
          justifyContent={"space-evenly"}
        >
          <Box
            display={"flex"}
            flexDir={"column"}
            gap={"4"}
            alignItems={variant === "desktop" ? "auto" : "center"}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Text fontWeight={"semibold"}>
                {site.footer.bottomGrid.rightItem.columnOne.title}
              </Text>
            </Box>
            {footerLinks.columnOne.map(linkGen)}
          </Box>
          <Box
            display={"flex"}
            flexDir={"column"}
            gap={"4"}
            alignItems={variant === "desktop" ? "auto" : "center"}
          >
            <Text fontWeight={"semibold"}>
              {site.footer.bottomGrid.rightItem.columnTwo.title}
            </Text>
            {footerLinks.columnTwo.map(linkGen)}
          </Box>
          <Box
            display={"flex"}
            flexDir={"column"}
            gap={"4"}
            alignItems={variant === "desktop" ? "auto" : "center"}
          >
            <Text fontWeight={"semibold"}>
              {site.footer.bottomGrid.rightItem.columnThree.title}
            </Text>
            {footerLinks.columnThree.map(linkGen)}
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      pos={"relative"}
      w={variant === "desktop" ? "100%" : "100"}
      h={variant === "desktop" ? "80vh" : ""}
      mx={variant === "desktop" ? "0" : "30px"}
      gridTemplateRows={variant === "desktop" ? "45% 55%" : "1fr"}
      borderRadius={"15px"}
      bgColor={"black"}
      color={"white"}
    >
      <Box
        w={variant === "desktop" ? "100%" : "100"}
        h={topBoxHeight}
        display={variant === "desktop" ? "grid" : "flex"}
        mx={variant === "desktop" ? "0" : "10px"}
        flexDir={"column"}
        gridRow={1}
        gap={variant === "desktop" ? "auto" : "5"}
        gridTemplateColumns={variant === "desktop" ? "1fr 1fr" : "1fr"}
        pos={"relative"}
        zIndex={"2"}
      >
        <Box
          alignSelf={variant === "desktop" ? "center" : "start"}
          mt={variant === "desktop" ? "0" : "40px"}
          mx={variant === "desktop" ? "0" : "10px"}
        >
          <Box
            display={"flex"}
            w={variant === "desktop" ? "100%" : "100"}
            justifyContent={variant === "desktop" ? "center" : "start"}
            alignItems={"center"}
            fontSize={titleSize}
            fontWeight={variant === "desktop" ? "800" : "700"}
            gap={variant === "desktop" ? "3" : "2"}
          >
            <Text>{site.footer.title.lineOne}</Text>
            <Text
              bgGradient={"linear(to-r, #29D5FB 5%, #047DFC 95%)"}
              bgClip={"text"}
            >
              {site.footer.title.colorWord}
            </Text>
            <Image
              boxSize={variant === "desktop" ? "80px" : "40px"}
              src={star}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            fontSize={titleSize}
            fontWeight={variant === "desktop" ? "800" : "700"}
          >
            <Text
              lineHeight={"shorter"}
              mt={variant === "desktop" ? "-20px" : "0"}
            >
              {site.footer.title.lineTwo}
            </Text>
          </Box>
        </Box>

        <Box
          display={"flex"}
          fontSize={variant === "desktop" ? "20px" : "15px"}
          justifyContent={variant === "desktop" ? "center" : "start"}
          alignItems={"start"}
          flexDir={"column"}
          gap={variant === "desktop" ? "2" : "5"}
          zIndex={"2"}
        >
          <Box
            w={variant === "desktop" ? "50%" : "100"}
            zIndex={"2"}
            mx={variant === "desktop" ? "0" : "10px"}
          >
            <Text>{site.footer.rhsText}</Text>
          </Box>
          <Button
            w={btnSize}
            padding={0}
            h={"60px"}
            pos={"relative"}
            ml={variant === "desktop" ? "0" : "10px"}
            gap={variant === "desktop" ? "5" : "2"}
            bgColor={"#232324"}
            borderRadius={variant === "desktop" ? "15px" : "10px"}
            zIndex={"5"}
            _hover={{ bgColor: "#2CD6F7" }}
          >
            <Text
              fontSize={variant === "desktop" ? "auto" : "12px"}
              color={"white"}
            >
              {site.footer.btn}
            </Text>
            <Box
              boxSize={variant === "desktop" ? "22px" : "18px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              bgColor={"#2CD6F7"}
              borderRadius={"100%"}
            >
              <Image
                src={rightArrow}
                h={variant === "desktop" ? "auto" : "12px"}
              />
            </Box>
          </Button>
        </Box>
      </Box>
      <Image
        pos={"absolute"}
        h={pipeSize}
        w={pipeWidth}
        top={imgPos}
        right={"0"}
        zIndex={"1"}
        src={topRightPipe}
      />
      {variant === "desktop" && <DesktopFooter />}
      {variant === "mobile" && <MobileFooter />}
    </Box>
  );
};
