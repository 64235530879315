import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import LandingPage from "../pages/landing/landing";
import PricingPage from '../pages/pricing/pricing';
import ToolsPage from '../pages/tools/tools';
import V2LandingPage from '../pages/landing-v2/landing-v2';

export enum RoutesList {
  Index = '/',
  Pricing = '/pricing',
  Tools = '/tools',
}

const Router: React.FC = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        {/* <Route path="/" element={<LandingPage />} /> */}
        <Route path="/" element={<V2LandingPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/tools" element={<ToolsPage />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
