import {
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';
import { AppColors } from '../../theme';
import image from '../../assets/backlinkit_links.png';

const ToolsHero: React.FC = () => {
  const gridRows = useBreakpointValue({
    base: 'repeat(6, 1fr)',
    lg: '1fr',
  });
  const gridColumns = useBreakpointValue({
    base: '1fr',
    lg: 'repeat(2, 1fr)',
  });
  return (
    <Flex
      p={gridRows === '1fr' ? 4 : 8}
      gap={12}
      mt={'-200px'}
      bgColor={AppColors.appBorder}
      h={{ base: '4xl', md: '5xl', lg: '3xl' }}
      align={'center'}
      justify={'space-evenly'}
    >
      <Grid
        gridTemplateColumns={gridColumns}
        gridTemplateRows={gridRows}
        gridRowGap={gridRows === '1fr' ? 0 : 3}
        p={gridRows === '1fr' ? 0 : 4}
      >
        <GridItem gridRowStart={gridRows === 'repeat(6, 1fr)' ? 4 : 1}>
          <Flex direction={'column'} gap={3} justify={'center'}>
            <Text
              fontWeight={'800'}
              fontSize={'5xl'}
              bgGradient={`linear(to-l, ${AppColors.secondary}, ${AppColors.primary})`}
              bgClip={'text'}
            >
              Keep track of your Backlinks!
            </Text>
            <Text fontSize={'lg'}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </Text>
            <Button
              border={`1px solid ${AppColors.appBackground}`}
              borderRadius={'full'}
              bgGradient={`linear(to-r, ${AppColors.secondary}, ${AppColors.primary})`}
              color={'white'}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
              size={'md'}
              maxW={'350px'}
            >
              Get started now with a free plan!
            </Button>
          </Flex>
        </GridItem>
        <GridItem
          alignItems={gridRows === '1fr' ? 'center' : 'start'}
          gridRowStart={gridRows === 'repeat(6, 1fr)' ? 5 : 1}
        >
          <Flex align={'center'} justify={'center'}>
            <Image src={image} w={{ base: '400px', md: '800px' }} />
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  );
};
export default ToolsHero;
