import { FAQ } from "../../models/faq";

export const toolFaqs: FAQ[] = [
    {
        question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
        answer: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        question: "At varius vel pharetra vel turpis nunc?",
        answer: "Neque convallis a cras semper auctor neque vitae tempus. Cursus in hac habitasse platea dictumst quisque sagittis purus sit. Condimentum id venenatis a condimentum vitae. Neque egestas congue quisque egestas diam in. Arcu dui vivamus arcu felis bibendum."
    },
    {
        question: "Id aliquet lectus proin nibh nisl condimentum id?",
        answer: "Senectus et netus et malesuada fames ac turpis egestas. Non sodales neque sodales ut etiam sit amet. Tempor nec feugiat nisl pretium fusce. Venenatis a condimentum vitae sapien pellentesque habitant morbi tristique. Ultrices gravida dictum fusce ut placerat orci nulla pellentesque dignissim. Arcu felis bibendum ut tristique et egestas."
    },
    {
        question: "Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel?",
        answer: "Posuere morbi leo urna molestie at elementum eu facilisis. Hac habitasse platea dictumst quisque. Arcu risus quis varius quam quisque id."
    },
    {
        question: "Diam donec adipiscing tristique risus?",
        answer: "Ultricies tristique nulla aliquet enim tortor at auctor urna nunc. Nunc sed velit dignissim sodales ut eu sem. Pretium viverra suspendisse potenti nullam ac tortor vitae purus faucibus. Vitae ultricies leo integer malesuada nunc vel risus commodo."
    }
]