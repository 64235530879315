import { theme } from './theme/index';
import { ChakraProvider } from '@chakra-ui/react';
import Router from './router/router';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { PanelServiceProvider } from '@backlinkit/shared';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <PanelServiceProvider>
          <Router></Router>
        </PanelServiceProvider>
      </Provider>
    </ChakraProvider>
  );
}

export default App;
