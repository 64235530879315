import { Box, Flex, Image, Link, useBreakpointValue } from '@chakra-ui/react';
import { AppColors } from '../../theme';
import rightArrow from '../../assets/right-arrow.svg';

import React from 'react';
import AppText from '../app-text/app-text';
import bannerImage from '../../assets/backlinkit-banner-image.png';
import { signupBetaUrl } from '@backlinkit/shared';

export const Banner: React.FC = () => {
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  return (
    <Flex
      width={'auto'}
      minH={'450px'}
      border={`2px solid ${AppColors.appBorder}`}
      borderRadius={'15px'}
      boxShadow={'-5px 5px rgba(0, 0, 0, 0.08)'}
      py={4}
      px={{ md: 4, lg: '50px' }}
      align={'center'}
      justify={'space-between'}
      mx={4}
      flexDir={variant === 'desktop' ? 'row' : 'column'}
    >
      <Flex flexDir={'column'} flex={1} gap={'20px'} p={4}>
        <AppText fontSize={'20px'} color={AppColors.tertiary} fontWeight={'500'}>
          BE ONE OF THE FIRST
        </AppText>
        <Flex flexDir={'column'} gap={'30px'} maxW={'80%'}>
          <AppText
            fontSize={variant === 'desktop' ? '40px' : '24px'}
            fontWeight={'600'}
            color={AppColors.tertiary}
          >
            Ready to beta test?
          </AppText>
          <AppText fontSize={'20px'} color={AppColors.tertiary} fontWeight={'500'}>
            As a beta tester, you'll get exclusive early access to cutting-edge features designed to
            help you monitor backlink drop-offs and keep your SEO campaigns on track.
          </AppText>
        </Flex>
        <Link href={signupBetaUrl}>
          <Flex width={'100%'} align={'center'} gap={'15px'}>
            <AppText fontSize={'24px'} fontWeight={'600'}>
              Let's do this
            </AppText>
            <Box
              boxSize={'22px'}
              display={'flex'}
              borderRadius={'100%'}
              bgColor={'#2CD6F7'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Image src={rightArrow} w={'11px'} />
            </Box>
          </Flex>
        </Link>
      </Flex>

      <Flex pos={'relative'} flex={1} minH={{ md: '100%', lg: '500px' }} p={4}>
        <Image
          src={bannerImage}
          pos={{ md: 'unset', lg: 'absolute' }}
          top={'15%'}
          right={4}
          bottom={0}
          height={{ md: '100%', lg: '511px' }}
          width={'auto'}
        />
      </Flex>
    </Flex>
  );
};
