import { Box, Flex } from "@chakra-ui/react";
import { Header } from "../../layouts/header/header";
import { Footer } from "../../layouts/footer/footer";
import PriceCards from "../../components/price-card/price-card";
import ClientFavourites from "../../components/plan-features/plan-features";
import PopularFAQ from "../../components/faq/faq";
import { faqs } from "../../components/faq/dummy-data";
import PricingHero from "../../components/pricing-hero/pricing-hero";
import { useEffect, useState } from "react";
import { ProductDto } from "../../models/products";

const PricingPage: React.FC = () => {
  const [products, setProducts] = useState<ProductDto[]>([]);

  const getBacklinkitProducts = async () => {
    try {
      const backlinkitProducts = await fetch(
        "https://backlinkit-dev.azurewebsites.net/product"
      ).then((res) => res.json());
      setProducts(backlinkitProducts);
    } catch (e) {
      console.log("error:", e);
    }
  };

  useEffect(() => {
    getBacklinkitProducts();
  }, []);

  console.log("products", products!);
  return (
    <Box overflow={"hidden"}>
      <Header />
      <PricingHero />
      <Flex mt={"-150px"}>
        <PriceCards products={products || []} />
      </Flex>
      <ClientFavourites />
      <PopularFAQ title="BacklinkIt FAQ's" faqs={faqs} />
      <Footer />
    </Box>
  );
};

export default PricingPage;
