import React from 'react';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import '../../public/styles.css';
import { Header } from '../../layouts/header/header';
import { V2Hero } from '../../components/hero/v2Hero';
import { Banner } from '../../components/banner/banner';
import { FeatureList } from '../../components/feature-list/feature-list';
import { SharedFooter, signupBetaUrl } from '@backlinkit/shared';
import { socialLinks } from '../../constants/socials';
import footerGraphic from '../../assets/Footer graphics.png';
import rightPipe from '../../assets/CTA graphics.png';

export const V2LandingPage: React.FC = () => {
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  return (
    <Flex flexDir={'column'} gap={'30px'} overflow={'hidden'}>
      <Header />
      <V2Hero />
      <Banner />
      <FeatureList />
      <SharedFooter
        signUpLink={signupBetaUrl}
        borderRadius={variant === 'desktop' ? '15px' : '0'}
        borderTopRadius={'15px !important'}
        rightPipe={rightPipe}
        leftPipe={footerGraphic}
        socialLinks={socialLinks}
      />
    </Flex>
  );
};

export default V2LandingPage;
