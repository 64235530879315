import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import { Header } from "../../layouts/header/header";
import { Footer } from "../../layouts/footer/footer";
import ToolsHero from "../../components/tools-hero/tools-hero";
import ReportsShowcase from "../../components/external-reports/reports-showcase";
import LinkCheckerShowcase from "../../components/link-checker/link-checker";
import PopularFAQ from "../../components/faq/faq";
import { toolFaqs } from "./dummyData";

const ToolsPage: React.FC = () => {
  const variant = useBreakpointValue({
    base: 'mobile',
    xl: 'desktop'
  })
  return (
    <Box overflow={"hidden"}>
      <Header />
      <ToolsHero />
      <Flex direction={"column"} gap={12} py={variant === 'desktop' ? 12 : 0}>
        <ReportsShowcase />
        <LinkCheckerShowcase />
        <PopularFAQ title='How can we help you?' faqs={toolFaqs} />
      </Flex>
      <Footer />
    </Box>
  );
};
export default ToolsPage;
