import { ColumnSortDir } from '../components/app-data-table/app-data-table-types';

export const getColumnSortDir = (columnSortDir: ColumnSortDir | undefined) => {
  if (columnSortDir) {
    switch (columnSortDir) {
      case ColumnSortDir.Asc:
        return ColumnSortDir.Desc;
      case ColumnSortDir.Desc:
        return undefined;
      default:
        break;
    }
  }
  return ColumnSortDir.Asc;
};
