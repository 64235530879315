import {
  Flex,
  Text,
  Grid,
  GridItem,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import image from '../../assets/backlinkit_links.png';
import { dummyData } from './dummy-data';
import { CheckIcon } from '@chakra-ui/icons';
import { AppColors } from '../../theme';

const ReportsShowcase: React.FC = () => {
  const gridRows = useBreakpointValue({
    base: 'repeat(2, 1fr)',
    lg: '1fr',
  });
  const gridColumns = useBreakpointValue({
    base: '1fr',
    lg: 'repeat(3, 1fr)',
  });
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  const titleSize = useBreakpointValue({
    base: '2xl',
    md: '4xl',
    lg: '5xl',
    xl: '5xl',
  });
  const textSize = useBreakpointValue({
    base: 'xs',
    sm: 'sm',
    md: 'sm',
  });
  return (
    <Flex
      align={'center'}
      justify={'center'}
      p={12}
      direction={'column'}
      gap={12}
    >
      <Grid
        gridTemplateColumns={gridColumns}
        gridTemplateRows={gridRows}
        gridColumnGap={variant === 'desktop' ? 10 : 3}
        gridRowGap={variant === 'desktop' ? '0' : 12}
        p={gridRows === '1fr' ? 12 : 0}
      >
        <GridItem colSpan={1} gridRowStart={gridRows === '1fr' ? 1 : 2}>
          <Flex align={'center'} justify={{ base: 'center', lg: 'end' }}>
            <Image src={image} maxW={'400px'} />
          </Flex>
        </GridItem>
        <GridItem colSpan={2} gridRowStart={gridRows === '1fr' ? 1 : 3}>
          <Flex
            direction={'column'}
            maxW={{ base: '2xl', md: '3xl' }}
            px={variant === 'desktop' ? '0' : 10}
          >
            <Text
              fontSize={titleSize}
              fontWeight={'800'}
              bgGradient={`linear(to-r, ${AppColors.secondary}, ${AppColors.primary})`}
              bgClip={'text'}
            >
              External Reports
            </Text>
            <Text fontSize={textSize}>{dummyData.description}</Text>
          </Flex>
        </GridItem>
      </Grid>
      <Flex
        w={'full'}
        p={gridRows === '1fr' ? 4 : 0}
        gap={{ base: 4, xl: 'auto' }}
        justify={'space-evenly'}
        direction={gridRows === '1fr' ? 'row' : 'column'}
      >
        {dummyData.features.map((x) => {
          return (
            <Flex
              direction={'column'}
              key={x.title}
              gap={3}
              p={12}
              borderRadius={'2xl'}
              boxShadow={'lg'}
            >
              <Text fontSize={'xl'} fontWeight={'600'}>
                {x.title}
              </Text>
              <Flex align={'center'} gap={3}>
                <CheckIcon color={AppColors.primary} />
                <Text fontSize={'md'}>{x.description}</Text>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
export default ReportsShowcase;
