import { Flex, Heading, List, Icon, Text, Box, useBreakpointValue } from '@chakra-ui/react';
import { listFeatures } from './dummy-data';
import { AppColors } from '../../theme';
import { CheckIcon } from '@chakra-ui/icons';

const ClientFavourites: React.FC = () => {
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  const cardGap = useBreakpointValue({
    base: 8,
    lg: 2,
    xl: 8,
  });
  return (
    <Flex direction={'column'} w={'full'} gap={12} py={12}>
      <Flex align={'center'} justify={'center'}>
        <Heading as={'h1'} fontSize={'4xl'} fontWeight={'500'} textAlign={'center'}>
          Client favourite features
        </Heading>
      </Flex>
      <Flex
        direction={variant === 'desktop' ? 'row' : 'column'}
        justify={'center'}
        align={'center'}
        gap={cardGap}
        py={12}
      >
        <Box boxShadow={'lg'} borderRadius={'2xl'} p={4} minW={'300px'}>
          <List>
            {listFeatures.slice(0, listFeatures.length / 3).map((x) => {
              return (
                <Flex p={4} gap={2} align={'center'} key={x}>
                  <Icon as={CheckIcon} color={AppColors.primary} />
                  <Text>{x}</Text>
                </Flex>
              );
            })}
          </List>
        </Box>
        <Box boxShadow={'lg'} borderRadius={'2xl'} p={4} minW={'300px'}>
          <List>
            {listFeatures.slice(listFeatures.length / 3, (listFeatures.length / 3) * 2).map((x) => {
              return (
                <Flex p={4} gap={2} align={'center'} key={x}>
                  <Icon as={CheckIcon} color={AppColors.primary} />
                  <Text>{x}</Text>
                </Flex>
              );
            })}
          </List>
        </Box>
        <Box boxShadow={'lg'} borderRadius={'2xl'} p={4} minW={'300px'}>
          <List>
            {listFeatures
              .slice((listFeatures.length / 3) * 2, (listFeatures.length / 3) * 3)
              .map((x) => {
                return (
                  <Flex p={4} gap={2} align={'center'} key={x}>
                    <Icon as={CheckIcon} color={AppColors.primary} />
                    <Text>{x}</Text>
                  </Flex>
                );
              })}
          </List>
        </Box>
      </Flex>
    </Flex>
  );
};
export default ClientFavourites;
