import React from "react";
import { Text, TextProps } from "@chakra-ui/react";
import { AppTextStyles } from "../../theme";

type AppTextProps = {
  variant?: AppTextStyles;
} & TextProps;

const AppText: React.FC<AppTextProps> = ({
  variant = "body",
  children,
  ...props
}) => {
  let asObj: any = variant.toString();

  if (variant === "body") {
    asObj = "p";
  }

  return (
    <Text
      as={asObj}
      lineHeight={{ base: "1.1", lg: "1.25", xl: "" }}
      textStyle={variant}
      {...props}
    >
      {children}
    </Text>
  );
};

export default AppText;
