export const listFeatures = [
    'Lorem ipsum',
    'consectetur adipiscing',
    'sed do eiusmod',
    'Sed euismod nisi',
    'A pellentesque',
    'Eget nunc',
    'aliquam faucibus',
    'Sit amet nulla',
    'lobortis mattis',
    'facilisi morbi',
    'Eu nisl nunc',
    'Risus feugiat'
]